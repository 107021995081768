<template>
  <div class="Index">
    <div v-loading="loadings"></div>
    <swiper :banners="banners"></swiper>

    <!-- 习剑 -->
    <div class="fence">
      <div class="fence-head">
        <div class="module_title tc">{{ library.title }}</div>
        <div class="module_subTitle tc">{{ library.subTitle }}</div>
      </div>
      <div class="fence-content" :class="clientWidth > 768 ? 'flex' : ''">
        <div class="fence-list flex" v-if="clientWidth > 768">
          <div v-for="(item,index) in library.items" :key="index" class="fence-list-item FenceIndex-content-right-list-item">
            <div class="fence-list-item-body">
              <div class="cur fence-list-item-banner" @click="goFenceIndex(item.url)">
                <img :src="item.image" width="100%" />
              </div>
              <div class="cur fence-list-item-title tc hidden" @click="goFenceIndex(item.url)"> {{ item.title }} </div>
              <div class="cur fence-list-item-subTitle" @click="goFenceIndex(item.url)"> {{ item.subTitle }} </div>
              <div class="xy-icon-nav" style="padding: 0" @click="goFenceIndex(item.url)">
                <div class="xy-icon-nav-box">
                  <img class="icon-bg cur" src="@/assets/PC/studyF/xz_l.png"/>
                  <i class="el-icon-top-right cur"></i>
                </div>
              </div>
            </div>
            <div style="height: 3px">
              <div class="bottom-bar"></div>
            </div>
          </div>
        </div>
        <div v-else class="fence-list-m">
          <div class="fence-mobile-item flex" v-for="(item, index) in fenceList_m" :key="item.id">
            <div class="fence-mobile-item-img">
              <img v-lazy="item.imgUrl" width="100%"/>
            </div>
            <div class="fence-mobile-item-body">
              <div class="fence-mobile-item-title hidden"> {{ item.title }} </div>
              <div class="flex3">
                <div class="fence-mobile-item-body-date">
                  {{ item.releaseTimeStr }}
                </div>
                <van-tag v-if="item.isNew" round type="danger" class="fence-mobile-item-body-IsNew">NEW</van-tag>
              </div>
              <div class="fence-mobile-item-introduction hidden"> {{ item.introduction }} </div>
              <div class="fence-mobile-item-tags flex4">
                <div v-for="(items, pindex) in item.tags" :key="pindex" class="tag-item">{{ items }}</div>
              </div>
              <div class="flex3">
                <div class="xy-icon">
                    <img src="@/assets/PC/studyF/study_type_k.png" />
                    <span> {{ item.pv }} </span>
                  </div>
                  <div class="xy-icon cur" @click="IsLikes(item.id, item.isPraise, index)" title="点赞">
                    <img v-if="item.isPraise" src="@/assets/PC/studyF/Liked.png" class="animate__animated animate__fadeIn"/>
                    <img v-else src="@/assets/PC/studyF/Like.png" />
                    <span> {{ item.praiseNum }} </span>
                  </div>
                  <div class="xy-icon" @click="Enshrines(item.id, item.isCollect, index)" title="收藏">
                    <van-icon v-if="item.isCollect" name="like" class="xy-icon-Collect animate__animated animate__fadeIn" color="#6b18a8"/>
                    <van-icon v-else name="like-o" class="xy-icon-Collect" />
                  </div>
              </div>
            </div>
          </div>
          <div class="tc mb15">
            <router-link to="/Home/Fence" class="xy-route-btn">
              <span>查看更多</span>
              <i class="el-icon-right"></i>
            </router-link>
          </div>
        </div>
        <div class="fence-right">
          <div class="fence_rank">
            <h3 class="study-leaderBoard-title tc">本月学习 TOP 榜</h3>
            <div class="study-tab">
              <a class="study-tab-item" href="javascript:void(0)" v-for="(item,index) in rankCategory" @click="checkRank(index)" :key="index" :class="[index==currentCategory?'study-tab-item-active':'']">{{item.title}}</a>
            </div>
            <div class="list" v-if="isRankCourse">
              <p class="item" v-for="(item,index) in rankCourse" :key="index" @click="goRankDetail(item.url)">
                <span>{{index+1}}</span>
                <span :title="item.title" class="rank-item-hover">{{item.title}}</span>
              </p>
            </div>
            <div class="list" v-else>
              <p class="item" v-for="(item,index) in rankCompany" :key="index">
                <span>{{index+1}}</span>
                <span :title="item.companyName">{{item.companyName}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 驭剑 -->
    <div class="forum_box">
      <div class="typeArea">
        <div class="module_title">{{ community.title }}</div>
        <div class="list">
          <div class="item transitions" v-for="(item,index) in forumList" :key="item.id">
            <div class="poster">
              <img :src="item.ImgUrl" alt="">
            </div>
            <div class="content">
              <h3 @click="toDetails(item.id)">{{item.title}}</h3>
              <p>发布于{{item.publishDateString}}</p>
              <p @click="toDetails(item.id)">{{ item.topic ? "#" + item.topic + "#" : ""}}</p>
              <p>{{item.introduction}}</p>
              <a href="javascript: void(0)" class="xy-route-btn" @click="toDetails(item.id)" v-if="clientWidth < 769">
                <span>点击进入</span>
                <i class="el-icon-right"></i>
              </a>
              <div class="tag">
                <p v-for="(industryItem, industryIndex) in item.industryName" :key="'1' + industryIndex" >{{ industryItem }}</p>
                <p v-for="(productItem, productIndex) in item.productName" :key="'2' + productIndex" >{{ productItem }}</p>
              </div>
              <div class="item_num">
                <a @click="like(item.id, item.isLike, index)" :class="[item.isLike ? 'active' : '']" title="点赞">
                  <svg-icon icon-class="sword"></svg-icon>
                  <svg-icon icon-class="sword_a"></svg-icon>
                  <span v-if="item.likeCount > 10000">{{ (item.likeCount / 10000).toFixed(1) }}w+</span>
                  <span v-else>{{ item.likeCount }}</span>
                </a>

                <a @click="collect(item.id, item.isCollect, index)" :class="[item.isCollect ? 'active' : '']" title="收藏">
                  <svg-icon icon-class="shield"></svg-icon>
                  <svg-icon icon-class="shield_a"></svg-icon>
                  <span v-if="item.collectCount > 10000">{{ (item.collectCount / 10000).toFixed(1) }}w+</span>
                  <span v-else>{{ item.collectCount }}</span>
                </a>

                <a :class="[item.isComment ? 'active' : '']" title="评论">
                  <svg-icon icon-class="discuss"></svg-icon>
                  <svg-icon icon-class="discuss_a"></svg-icon>
                  <span v-if="item.comment > 10000">{{ (item.comment / 10000).toFixed(1) }}w+</span>
                  <span v-else>{{ item.comment }}</span>
                </a>

                <a title="转发">
                  <svg-icon icon-class="share"></svg-icon>
                  <span>{{ item.shareNum }}</span>
                  <ul>
                    <li>
                      <a>
                        <img src="../../assets/PC/forum/icon_copy.png" />
                        <span v-clipboard:copy="item.link" v-clipboard:success="onCopy">复制链接</span>
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="../../assets/PC/forum/icon_sina.png" />
                        <span @click="toWeibo(item.title, item.id)">新浪微博</span>
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="../../assets/PC/forum/icon_wechat.png"/>
                        <span>微信扫一扫</span>
                      </a>
                    </li>
                    <li class="qrCode" :id="'qrCode' + index"></li>
                  </ul>
                </a>
              </div>
              <div class="router_icon" @click="toDetails(item.id)">
                <img src="../../assets/PC/forum/shield_purple.png" />
                <i class="el-icon-top-right"></i>
              </div>
            </div>
            <div class="bottom-bar"></div>
          </div>
        </div>
        <div class="tc mb15">
          <router-link to="/Home/Forum" class="xy-route-btn">
            <span>查看更多</span>
            <i class="el-icon-right"></i>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 剑鸣 -->
    <div class="Index-title">{{ activity.title }}</div>
    <calendar></calendar>
    <!-- <div class="foot">
      <p><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021009209号</a></p>
      <p></p>
    </div> -->
  </div>
</template>

<script>
  import swiper from "@/components/Swiper.vue";
  import { getHomemodule, GetBanner, GetRank, GetRankCompany, GetForum, GetFence } from "@/api/Home.js"
  import { Praise, UnPraise, Collect, UnCollect } from '@/api/library.js'
  import * as forum from "../../api/forum.js";
  import QRCode from "qrcodejs2";
  import { debounce, GetWxShares, GetShareData } from "@/utils/common";
  import { mapState } from 'vuex';
  import { ApiToken } from '@/utils/ApiToken'

  import calendar from '@/components/calendar'

  export default {
    name: "Index",
    data() {
      return {
        current: 0,
        fenceList_m: [],
        rankCategory: [{
          id: '',
          title: '课程'
        },{
          id: '',
          title: '公司'
        }],
        currentCategory: 0,
        rank: [],
        rankCourse: [],
        rankCompany: [],
        isRankCourse: true,
        forumList: [],
        loadings: true, // !主页loading
        banners: [], // !首页banner
        library: "", // !习剑
        activity: "", // !剑鸣
        community: "",
      };
    },
    mounted() {
      GetWxShares()
      GetShareData(1,'Index', '')
      this.getRank()
      this.getFence()
      this.getForum()

      getHomemodule().then((res)=>{
        if(res.status == 1) {
          const { activity, community, library } = res.data;
          this.library = library;
          this.activity = activity;
          this.community = community;
        }else {
          this.$toast(res.message);
        }
        this.loadings = false
      });
      // !获取banner
      GetBanner().then((res) => {
        if (res.status === 1) {
          if(document.documentElement.clientWidth > 750) {
            this.banners = res.data.home
          } else {
            this.banners = res.data.home_m
          }
        }
      });
    },
    methods:{
      checkRank(index){
        this.currentCategory = index
        this.isRankCourse = index==0?true:false
      },
      goRankDetail(url){
        window.location.href = url
      },
      getRank(){
        GetRank().then(res => {
          if(res.status==1){
            this.rankCourse = res.data.libraryInfos
          }
        })
        GetRankCompany().then(res => {
          if(res.status==1){
            this.rankCompany = res.data.companys
          }
        })
      },
      getFence(){
        GetFence().then(res => {
          if(res.status == 1){
            this.fenceList_m = res.data
          }
        })
      },
      // !去习剑列表页
      goFenceIndex(url) {
        window.location.href = url
      },
      // !去详情页
      goFenceDetail(Id) {
        this.$router.push("/Home/FenceDetail?id=" + Id);
      },
      // !点赞
      IsLikes(id, IsTrue,index) {
        const data = { resourcesId: id, type: 0 };
        if(!this.$store.state.token){
          this.$toast('请登录后操作!')
          return false;
        }
        debounce(() => {
          if(!IsTrue){
            Praise(data).then( res => {
              if (res) {
                this.$toast(res.message);
                this.fenceList_m[index].isPraise = true
                this.fenceList_m[index].praiseNum++
              }
            });
          }else{
            UnPraise(data).then( res => {
              if (res) {
                this.$toast(res.message);
                this.fenceList_m[index].isPraise = false
                this.fenceList_m[index].praiseNum--
              }
            });
          }
        }, 3000);
      },
      // !收藏
      Enshrines(id, IsTrue,index) {
        const data = { libraryId: id };
        if(!this.$store.state.token){
          this.$toast('请登录后操作!')
          return false;
        }
        debounce(() => {
          if (!IsTrue) {
            Collect(data).then((res) => {
              this.$toast(res.message);
              this.fenceList_m[index].isCollect = true
            });
          } else {
            UnCollect(data).then((res) => {
              this.$toast(res.message);
              if (res.status == 1) {
                this.fenceList_m[index].isCollect = false
              }
            });
          }
        }, 1000);
      },
      getForum(){
        GetForum().then(res => {
          if(res.status==1){
            Array.from(document.querySelectorAll(".qrCode")).map((v) => {
              v.innerHTML = "";
            });
            res.data.map((item, index) => {
              item.ImgUrl = require('../../assets/PC/home/forum_default.png')
              item.link = ApiToken.shareUrl + "/Home/forumDetails?id=" + item.id;
              if (item.industry != "") {
                item.industry = JSON.parse(item.industry);
                item.industryName = JSON.parse(item.industryName);
              } else {
                item.industry = [];
                item.industryName = [];
              }
              if (item.product != "") {
                item.product = JSON.parse(item.product);
                item.productName = JSON.parse(item.productName);
              } else {
                item.product = [];
                item.productName = [];
              }
            })
            this.forumList = res.data;
            if(this.clientWidth > 768) {
              this.forumList.length = 3
            }
            this.forumList.map((item, index) => {
              this.$nextTick(function () {
                this.qrCode(item.link, "qrCode" + index);
              });
            });
          }
        })
      },
      // !去详情页
      toDetails(id) {
        this.$router.push("/Home/forumDetails?id=" + id);
      },
      // !收藏
      collect(id, isCollect, index) {
        if(!this.$store.state.token){
          this.$toast("请登录后操作!");
          return false;
        }
        debounce(() => {
          if (!isCollect) {
            forum.collectArticle({forumManagementId: id,}).then((res) => {
              if (res.status == 1) {
                if (res.message > 0) {
                  this.$toast("收藏成功！ +" + res.message + " 积分");
                }else{
                  this.$toast("收藏成功！")
                }
                this.forumList[index].isCollect = true;
                this.forumList[index].collectCount++;
              }else{
                this.$toast(res.message)
              }
            });
          } else {
            forum.collectCancelArticle({forumManagementId: id}).then((res) => {
              this.$toast({
                message: res.message,
                duration: 1000,
              });
              if (res.status == 1) {
                this.forumList[index].isCollect = false;
                this.forumList[index].collectCount--;
              }
            });
          }
        }, 1000);
      },
      // !点赞
      like(id, isLike, index) {
        if(!this.$store.state.token){
          this.$toast({
            message: "请登录后操作!",
          });
          return false;
        }
        debounce(() => {
          if (!isLike) {
            forum.likeArticle({ forumManagementId: id }).then((res) => {
              if (res.status == 1) {
                if (res.message > 0) {
                  this.$toast("点赞成功！ +" + res.message + " 积分");
                } else {
                  this.$toast("点赞成功！");
                }
                this.forumList[index].isLike = true;
                this.forumList[index].likeCount++;
              }
            });
          } else {
            forum.likeCancelArticle({ forumManagementId: id }).then((res) => {
              this.$toast({ message: res.message, duration: 1000 });
              if (res.status == 1) {
                this.forumList[index].isLike = false;
                this.forumList[index].likeCount--;
              }
            });
          }
        }, 1000);
      },
      // !记录分享次数
      shareNum(id) {
        const Id = { forumManagementId: id };
        forum.shareNum(Id).then((res) => {});
      },
      // !分享新浪微博
      toWeibo(title, id) {
        this.shareNum(id);
        var url = ApiToken.shareUrl + "Home/forumDetails?id=" + id;
        url = encodeURIComponent(url);
        var a = screen.width / 2 - 300,
          d = screen.height / 2 - 175;
        window.open(
          "http://v.t.sina.com.cn/share/share.php?title=" + title + "&url=" + url,
          "Ignite",
          "status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=0,width=800,height=530,top=" +
            d +
            ",left=" +
            a
        );
      },
      // !草料二维码
      qrCode(link, id) {
        this.$nextTick(()=>{
          new QRCode(id, {
            text: link + '&code='+ Math.random(),
            width: 110,
            height: 110,
            correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
          })
        })
      },
      // !复制链接
      onCopy(e) {
        this.shareNum(e.text.split("id=")[1]);
        this.$toast({ message: "复制成功!", duration: 1000 });
      },
    },
    computed: {
      ...mapState(['clientWidth'])
    },
    components: {
      swiper,
      calendar
    },
  };
</script>

<style lang="scss" scoped>
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

@import './index.scss';
@import '../Fence/FenceIndex/index.scss';

// .nav_bottom{ display: none; }
.rank-item-hover:hover{
  color: #6B18A8 !important;
  // font-weight: bold;
  cursor: pointer;
}
.fence{
    .list{
      padding: 6px 0 0;
      .item{
        width: 100%;
        display: flex;
        align-items: flex-end;
        margin: 12px 0 0;
        span{
          &:first-of-type{
            width: 46px;
            height: 36px;
            margin: 0 8px 0 0;
            font-size: 16px;
            font-weight: bolder;
            color: rgba(115, 130, 148, 1);
            display: flex;
            justify-content: center;
            align-items: flex-end;
          }
          &:last-of-type{
            width: calc( 100% - 54px );
            max-width: calc( 100% - 54px );
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            color: rgba(115, 130, 148, 1);
          }
        }
        &:first-of-type{
          span{
            &:first-of-type{
              background: url(../../assets/PC/home/fir.png) no-repeat;
              background-size: 100%;
              color: #fff;
            }
          }
        }
        &:nth-of-type(2){
          span{
            &:first-of-type{
              background: url(../../assets/PC/home/sec.png) no-repeat;
              background-size: 100%;
              color: #fff;
            }
          }
        }
        &:nth-of-type(3){
          span{
            &:first-of-type{
              background: url(../../assets/PC/home/thi.png) no-repeat;
              background-size: 100%;
              color: #fff;
            }
          }
        }
        &:nth-of-type(4){
          span{
            &:first-of-type{
              background: url(../../assets/PC/home/thi.png) no-repeat;
              background-size: 100%;
              color: #fff;
            }
          }
        }
        &:nth-of-type(5){
          span{
            &:first-of-type{
              background: url(../../assets/PC/home/thi.png) no-repeat;
              background-size: 100%;
              color: #fff;
            }
          }
        }
      }
    }
}
// 剑盟论坛
.forum_box { width: 100%;background: #f8f8f8;
  .typeArea {
    padding: 80px 0;
    .module_title {
      width: 100%;
      font-size: 48px;
      font-weight: bold;
      color: #363d42;
      line-height: 1;
      text-align: center;
      margin-bottom: 40px;
    }
    .list{
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin: 0 0 32px;
      .item{
        width: calc( ( 100% - 40px )/3 );
        margin: 0 20px 0 0;
        &:last-of-type{
          margin: 0;
        }
        .poster{
          img{
            width: 100%;
          }
        }
        .bottom-bar{width: 0%;height: 3px;margin: 0 auto;background: #6B18A8FF;transition: all 0.5s;}
        .content{
          padding: 24px 28px 40px 26px;
          background: #fff;
          h3{
            font-size: 16px;
            color: rgba(54, 61, 66, 1);
            font-weight: bolder;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }
          &>p{
            &:first-of-type{
              font-size: 16px;
              color: rgba(115, 130, 148, 1);
              margin: 4px 0 8px;
            }
            &:nth-of-type(2){
              font-size: 16px;
              color: rgba(107, 24, 168, 1);
              line-height: 24px;
            cursor: pointer;
            }
            &:last-of-type{
              font-size: 16px;
              color: rgba(115, 130, 148, 1);
              line-height: 24px;
              margin: 3px 0 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .tag{
            width: 100%;
            height: 44px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            p{
              display: block;
              padding: 0 10px;
              line-height: 27px;
              border: 1px solid RGBA(230, 230, 230, 1);
              font-size: 16px;
              font-weight: bolder;
              color: rgba(107, 24, 168, 1);
              margin: 0 12px 15px 0;
            }
          }
          .item_num {
            display: flex;

            & > a {
              display: flex;
              align-items: center;
              margin-right: 28px;
              position: relative;

              span {
                font-size: 16px;
                color: #363d42;
                margin-left: 8px;
              }

              svg {
                transition: all 0.5s;
              }

              svg:first-of-type {
                opacity: 1;
              }

              svg:nth-of-type(2) {
                position: absolute;
                left: 0;
                opacity: 0;
              }

              &:first-of-type {
                cursor: pointer;

                svg {
                  width: 17px;
                  height: 17px;
                }
              }

              &:nth-of-type(2) {
                cursor: pointer;

                svg {
                  width: 17px;
                  height: 21px;
                }
              }

              &:nth-of-type(3) {
                svg {
                  width: 18px;
                  height: 18px;
                }
              }

              &:nth-of-type(4) {
                cursor: pointer;
                position: relative;

                svg {
                  width: 18px;
                  height: 18px;
                }

                ul {
                  display: none;
                  width: 156px;
                  height: 295px;
                  background: url(../../assets/PC/forum/share_bac.png)
                    center no-repeat;
                  background-size: 100%;
                  padding: 20px 0 0 0;
                  position: absolute;
                  top: 20px;
                  left: -70px;
                  z-index: 5;

                  li {
                    width: 100%;
                    line-height: 36px;
                    margin-top: 8px;
                    display: flex;
                    padding-left: 22px;
                    align-items: center;

                    span {
                      font-size: 16px;
                      color: #6c7179;
                      margin-left: 6px;
                    }

                    &:first-of-type {
                      img {
                        width: 20px;
                      }
                    }

                    &:nth-of-type(2) {
                      img {
                        width: 22px;
                      }
                    }

                    &:nth-of-type(3) {
                      img {
                        width: 22px;
                      }
                    }

                    &:nth-of-type(4) {
                      justify-content: center;
                      padding: 0;

                      img {
                        width: 104px;
                      }
                    }

                    &:nth-of-type(5) {
                      display: none;
                    }
                  }
                }
              }

              &.active {
                border: 0;
                background: none;

                svg:first-of-type {
                  opacity: 0;
                }

                svg:nth-of-type(2) {
                  opacity: 1;
                }
              }
            }
          }

          .router_icon {
            width: 31px;
            height: 38px;
            position: relative;
            cursor: pointer;
            margin: 27px 0 0;
            img {
              opacity: 0;
              transition: all 0.5s;
              position: absolute;
            }

            i {
              font-size: 15px;
              font-weight: 700;
              transition: all 0.5s;
              margin: 12px 0 0 8px;
            }
          }
        }
        &:hover{
          .router_icon {
            img {
              opacity: 1;
            }

            i {
              color: white;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .moreForum{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 157px;
      height: 36px;
      line-height: 36px;
      background: RGBA(107, 24, 168, 1);
      margin: 0 auto;
      span{
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        margin: 0 12px 0 0;
      }
      i{
        font-size: 20px;
        transition: all .5s;
        color: rgba(255, 255, 255, 1);
      }
    }
    .forum_con_box {
      width: 100%;
      display: flex;
      background: #fff;
      .forum_con_l_box {
        width: 50%;
        position: relative;
        .forum_con_l {
          position: absolute;
          top: 100px;
          left: 40px;
          right: 40px;
          .forum_con_l_con {
            font-size: 16px;
            font-weight: 400;
            color: #738294;
            line-height: 1.5;
          }
        }
      }
      .forum_con_r_box {
        display: block;
        width: 50%;
      }
      .forum_con_l_box_m {
        display: none;
      }
    }
  }
}

//移动端
@media (max-width: 750px) {
  .fence {
    .list{
      margin: vw(30);
      .item{
        margin: vw(10) 0 0;
        padding: 0 vw(23);
        span{
          &:first-of-type{
            width: 31px;
            height: 24px;
            margin: 0 15px 0 0;
            font-size: vw(22);
          }
          &:last-of-type{
            width: calc( 100% - 46px );
            max-width: calc( 100% - 46px );
            font-size: vw(24);
          }
        }
      }
    }
  }

  // 剑盟论坛
  .forum_box {
    .forum {margin: 0 auto;margin-top: vw(40);}
    .typeArea {
      padding: vw(30);
      .module_title {
        font-size: vw(38);
        margin-bottom: vw(40);
      }
      .list{
        flex-wrap: wrap;
        margin: 0 0 vw(32);
        .item{
          width: 100%;
          display: flex;
          align-items: center;
          margin: 0 0 vw(39) 0;
          background: #fff;
          &:last-of-type{
            margin: 0 0 vw(39) 0;
          }
          .poster{
            width: 166px;
            margin: 0;
            img{
              width: 100%;
            }
          }
          .bottom-bar{display: none;}
          .content{
            width: calc( 100% - 166px );
            max-width: calc( 100% - 166px );
            padding: vw(24) vw(32) vw(24) 18px;
            h3{
              font-size: vw(28);
              font-weight: normal;
            }
            &>p{
              &:first-of-type{
                display: none;
              }
              &:nth-of-type(2){
                font-size: vw(24);
                line-height: vw(36);
                margin: vw(17) 0;
              }
              &:last-of-type{
                display: none;
              }
            }
            .tag,.item_num,.router_icon{
              display: none;
            }
          }
        }
      }
      .forum_con_box {
        width: 100%;
        display: block;
        background: #fff;
        .forum_con_l_box {
          display: none;
        }
        .forum_con_l_box_m {
          display: block;
          width: 100%;
          position: relative;
          .forum_con_l {
            position: static;
            padding: vw(40) 0 ;
            .forum_con_l_con {
              font-size: vw(28);
              text-align: center;
            }
            .forum_con_l_but {
              padding: vw(20) vw(70);
              margin: 0 auto;
              margin-top: vw(40);
              .forum_con_l_but_con {
                font-size: vw(24);
              }
            }
          }
        }
        .forum_con_r_box {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .forum{margin: 0 auto;margin-top: 42px;}
}
</style>
<style lang="scss">
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .forum {width: 156px;height: 36px;background: #6B18A8;margin-top: 42px;}
  .forum a {height: 100%;display: flex;align-items: center;}
  .forum-go {color: #fff;font-size: 16px;margin: 0 13px 0 30px;}
  .forum .el-icon-right{color: #fff;font-size: 19px;}
  @media (max-width: 750px) {
    .forum .el-icon-right{color: #fff;font-size: 16px;}
    .forum-go{font-size: vw(24);margin: 0 vw(45) 0 vw(86);}
  }
</style>