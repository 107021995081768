<template>
  <div class="calendar">
    <div class="calendar-body">
      <div class="calendar-left">
        <!-- <h3><span class="co-6b1">{{ActivityDate.month}}月</span>活动抢先看</h3> -->
		<h3>往期活动回顾</h3>
        <ul>
          <li class="calendar-list-item" v-for="item in week" :key="item.entTitle">
            <span>
              {{ item.enTitle }}
              <br>
              {{ item.title }}
            </span>
          </li>
        </ul>
        <div class="calendar-left-line"></div>
        <div class="calendar-left-body">
          <div class="calendar-list">
            <div class="calendar-list-item" v-for="(item, index) in monthDetail" :key="index" @click="clickMore(item)">
              <!-- <span v-if="item.day" :class="ActivityDate.day == item.day ? 'xy-btn-active' : ''">{{item.day}}</span> -->
              <span v-if="item.day" :class="ActivityDate.day == item.day ? (item.day == 12 || item.day == 14 ? 'day-active xy-btn-active' : 'xy-btn-active') : (item.day == 12 || item.day == 14 ? 'day-active' : '')">{{item.day}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-right">
        <div class="calendar-right-body">
          <h3 class="co-6b1" v-html="ActivityDate.title"> </h3>
          <!-- <div class="calendar-right-body-date flex2">
            <img src="@/assets/PC/home/icon_date.png" alt="" />
            <div>
              日期：{{ ActivityDate.month }}月{{ ActivityDate.day }}日
            </div>
          </div>
          <div class="calendar-right-body-time flex2">
            <img src="@/assets/PC/home/icon_time.png" alt="" />
            <div>
              时间：{{ ActivityDate.time }}
            </div>
          </div> -->
          <div class="calendar-right-body-time flex2" v-if="true">
            <img src="@/assets/PC/home/icon_dates.png" alt="" />
            <div>
              日期：{{ ActivityDate.month }}月{{ ActivityDate.day }}日
            </div>
          </div>
          <div class="calendar-right-body-date flex2" v-if="true">
            <img src="@/assets/PC/home/icon_address.png" alt="" />
            <div>
              地点：{{ ActivityDate.address }}
            </div>
          </div>
          <div class="calendar-right-body-link">
            <a class="xy-route-btn" target="_blank" :href="ActivityDate.link">
              <span>点击查看精彩回顾</span>
              <i class="el-icon-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiToken } from '@/utils/ApiToken'
export default {
  name: 'calendar',
  data() {
    return {
      // ! 活动年份
      year: new Date().getFullYear(),
      // ! 星期
      week: [
        { title: '周日', enTitle: 'Sun', key: 7 },
        { title: '周一', enTitle: 'Mon', key: 1 },
        { title: '周二', enTitle: 'Tue', key: 2 },
        { title: '周三', enTitle: 'Wed', key: 3 },
        { title: '周四', enTitle: 'Thu', key: 4 },
        { title: '周五', enTitle: 'Fri', key: 5 },
        { title: '周六', enTitle: 'Sat', key: 6 }
      ],
      // ! 月份信息
      monthDetail: [],
      // ! 活动字段
      ActivityDate: {
        // $ 活动月份
        month: '',
        // $ 活动日期
        day: '10',
        // $ 活动链接
        link: 'https://live.mircoservice.com/aveva2022?code=Community',
        // $ 活动标题
        title: '论剑2022 剑维软件线上先锋论坛',
        // $ 活动时间
        time: '9:30 - 12:30',
        // 地点
        address: '上海'
      }
    }
  },
  mounted () {
    this.GetInit();
  },
  methods: {
    // ! 初始化活动信息
    GetInit () {
      this.ActivityDate = {
        month: '9',
        day: '12',
        // link: 'https://live.mircoservice.com/aveva2022?code=Community',
        link: ApiToken.shareUrl + 'WorkshopReview',
        title: '“融汇数字创新 释放工业动能”2023 剑维软件行业研讨会 丨宁波',
        time: '9月14日',
        address: '宁波洲际酒店'
      }
      this.GetCalendar()
    },
    // ! 获取当前月份、月份第一天为星期几
    GetCalendar() {
      // $ 当前月份天数
      const monthDay = new Date(this.year, this.ActivityDate.month, 0).getDate()
      // $ 当前月份第一天为星期几
      const now = new Date()
      now.setFullYear(this.year)
      now.setMonth(this.ActivityDate.month - 1)
      now.setDate(1)
      const monthDayWeek = now.getDay();
      // this.GetDayList(monthDay, monthDayWeek)
      this.GetDayList(monthDay, 5)
    },
    // ! 循环获取月份、星期信息  monthDay：(当前月份)  monthDayWeek：(月份第一天为星期几)
    GetDayList(monthDay, monthDayWeek) {
      for (let week = 0; week < monthDayWeek; week++ ) {
        this.monthDetail.push({
          day: '',
          week: ''
        })
      }
      for (let a = 1; a <= monthDay; a++ ) {
        monthDayWeek == 7 ? monthDayWeek = 0 : monthDayWeek
        this.monthDetail.push({
          day: a,
          week: monthDayWeek
        })
        monthDayWeek++
      }
    },
    clickMore(item){
      if(item.day == 12){
        this.ActivityDate = {
          month: '9',
          day: '12',
          link: ApiToken.shareUrl + 'WorkshopReview',
          title: '“融汇数字创新 释放工业动能”2023剑维软件行业研讨会 丨宁波',
          time: '9月12日',
          address: '宁波洲际酒店'
        }
      }else if(item.day == 14){
        this.ActivityDate = {
          month: '9',
          day: '14',
          link: ApiToken.shareUrl + 'WorkshopReview',
          title: '“融汇数字创新 释放工业动能”2023剑维软件行业研讨会 丨西安',
          time: '9月14日',
          address: '西安高新区喜来登酒店'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>